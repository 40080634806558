import React from 'react';
import "./cover.css";
import Logo from './logo';

const Cover: React.FC = () => (
    <div className="cover">
        <a>[start a new coin]</a>
        <div className="inner-cover">
            <img alt="king of the hill" loading="lazy" width="166" height="32" decoding="async" data-nimg="1"
                 src="/images/king-of-the-hill.webp"/>
            <Logo/>
        </div>
    </div>
);

export default Cover;