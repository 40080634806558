import React from 'react';
import "./fun-item.css";

type FunItemProps = {
    index: number;
    title: string;
    cover: string;
    avatar: string;
    createdBy: string;
    marketCap: string;
    replies: number;
    content: string;
};

const FunItem: React.FC<FunItemProps> = (props) => {
    const {
        index,
        title,
        cover,
        avatar,
        createdBy,
        marketCap,
        replies,
        content
    } = props;

    return (
        <div key={`${Math.random().toString(36).substr(2, 9)}-${index}`} className="fun-item-container">
            <div className="image-container">
                <img
                    alt="cover"
                    loading="lazy"
                    width="200"
                    height="200"
                    className="image"
                    src={cover}
                />
            </div>
            <div className="content">
                <div className="creator-info">
                    <div>Created by</div>
                    <button type="button" className="creator-button">
            <span className="creator-details">
              <img
                  alt=""
                  loading="lazy"
                  width="16"
                  height="16"
                  className="creator-image"
                  src={avatar}
              />
              <span className="creator-name">{createdBy}</span>
            </span>
                    </button>
                </div>
                <div className="market-info">
                    market cap: {marketCap}
                    <div className="badge-info">
                        [badge: <img loading="lazy" width="18" height="18" src="/images/king.webp"/>]
                    </div>
                </div>
                <p className="replies-info">replies: {replies}</p>
                <p className="fun-item-title">
                    <span style={{fontWeight: "bold"}}>{title}</span> {content}
                </p>
            </div>
        </div>
    );
}

export default FunItem;