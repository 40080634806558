import React, {useEffect, useState} from 'react';
import Header from "../components/header";
import Cover from '../components/cover';
import Search from '../components/search';
import "./home.css";
import {Button, Radio, Select, Skeleton, Space, Tabs, TabsProps} from "antd";
import FunItem from "../components/fun-item";
import Footer from '../components/footer';

const Homepage: React.FC = () => {
    const [sortBy, setSortBy] = useState<string>("featured");
    const [funContents, setFunContents] = useState<any[]>([]);
    const [isRandomizing, setIsRandomizing] = useState<boolean>(true);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Following',
        },
        {
            key: '2',
            label: 'Terminal',
        },
    ];

    useEffect(() => {
        fetch('/data.json') // Adjust the path based on where you place the file
            .then(response => response.json())
            .then(jsonData => {
                setTimeout(() => {
                    setFunContents(jsonData)
                }, 1000)
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const shuffleArray = (array: any[]) => {
        return array.sort(() => Math.random() - 0.5);
    };

    const getRandomInRange = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (isRandomizing) {
            // If randomizing is enabled, start the interval
            intervalId = setInterval(() => {
                setFunContents(prevFunContents => shuffleArray([...prevFunContents]));
            }, getRandomInRange(800, 2500));
        }

        // Clear the interval when randomizing is turned off or the component unmounts
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isRandomizing, funContents]);

    return (
        <div className="home-page">
            <Header/>
            <div style={{height: "100%"}}>
                <div className="main-container">
                    <Cover/>
                    <Search/>
                    <div className="tabs">
                        <div className="tab-header">
                            <Tabs
                                defaultActiveKey="2"
                                items={items}
                                className="tab-control"
                            />
                            <div className="tab-options">
                                <Select
                                    size="large"
                                    value={sortBy}
                                    popupMatchSelectWidth={true}
                                    onChange={key => setSortBy(key)}
                                    options={[
                                        {value: 'bump-order', label: 'sort: bump order'},
                                        {value: 'featured', label: 'sort: featured 🔥'},
                                        {value: 'created-time', label: 'sort: creation time'},
                                        {value: 'last-reply', label: 'sort: last reply'},
                                        {value: 'currently-live', label: 'sort: currently live'},
                                        {value: 'market-cap', label: 'sort: market cap'},
                                    ]}
                                />
                                {
                                    sortBy !== "featured" && (
                                        <Select
                                            size="large"
                                            defaultValue="asc"
                                            popupMatchSelectWidth={true}
                                            options={[
                                                {value: 'asc', label: 'order: asc'},
                                                {value: 'desc', label: 'order: desc'},
                                            ]}
                                        />
                                    )
                                }
                                <Space direction="vertical" style={{color: "white"}}>
                                    <Space>
                                        <span>Show animations:</span>
                                        <Radio.Group size="small"
                                                     value={isRandomizing ? "on" : "off"}
                                                     onChange={e => setIsRandomizing(e.target.value == "on")}>
                                            <Radio.Button value="on">On</Radio.Button>
                                            <Radio.Button value="off">Off</Radio.Button>
                                        </Radio.Group>
                                    </Space>
                                    <Space>
                                        <span>Include nsfw:</span>
                                        <Radio.Group size="small" defaultValue="off">
                                            <Radio.Button value="on">On</Radio.Button>
                                            <Radio.Button value="off">Off</Radio.Button>
                                        </Radio.Group>
                                    </Space>
                                </Space>
                            </div>
                        </div>
                    </div>
                    <div className="fun-content">
                        {!funContents.length && Array.from({length: 30}, (_, index) => index + 1).map(item => (
                            <Skeleton avatar active paragraph={{rows: 4}}/>
                        ))}
                        {funContents.map((item, index) => <FunItem
                            index={index}
                            title={item.title}
                            cover={item.cover}
                            avatar={item.avatar}
                            createdBy={item.createdBy}
                            marketCap={item.marketCap}
                            replies={item.replies}
                            content={item.content}
                        />)}
                    </div>
                    <div className="pagination">
                        <div>
                            <Button type="link" disabled style={{color: "#94a3b8"}}>{"[ << ]"}</Button>
                            <span style={{color: "#fff"}}>1</span>
                            <Button type="link" style={{color: "#fff"}}>{"[ >> ]"}</Button>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default Homepage;