import React from 'react';
import {ConfigProvider} from 'antd';
import Homepage from "./pages/home";

const App: React.FC = () => (
    <ConfigProvider theme={{
        token: {colorPrimary: '#87efad'},
        components: {
            Select: {
                selectorBg: "#87efad",
                optionActiveBg: "#87efad"
            },
        },
    }}>
        <Homepage/>
    </ConfigProvider>
);

export default App;