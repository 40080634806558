import React, {useEffect, useState} from 'react';
import "./logo.css";

const Logo: React.FC = () => {
    const [funContent, setFunContent] = useState<any>({
        "title": "Holy Crap (ticker: HC):",
        "cover": "https://pump.mypinata.cloud/ipfs/QmWCSuNJxEgcMk8jGv1Jn8w2BCzMpMu5eRfBRAHqhpzK52?img-width=128&img-dpr=2&img-onerror=redirect",
        "avatar": "https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1?img-width=16&img-dpr=2&img-onerror=redirect",
        "createdBy": "4nivKb",
        "marketCap": "20.43K",
        "replies": 823,
        "content": "HC): Holy Crap, this could go to millions. Easily memeable, I will buy a small bag and let the community find its success or failure..No socials until it hits Raydium.. Hit Ray=open TG, coordinate a marketing plan with the holders."
    });

    useEffect(() => {
        fetch('/data.json') // Adjust the path based on where you place the file
            .then(response => response.json())
            .then(jsonData => {
                setTimeout(() => {
                    setFunContent(jsonData[Math.floor(Math.random() * jsonData.length)])
                }, 1000)
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <div className="logo-container">
            <div className="logo-image-container">
                <img
                    alt="NWOG"
                    loading="lazy"
                    width="80"
                    height="80"
                    className="logo-image"
                    src={funContent.cover}
                />
            </div>
            <div className="content">
                <div className="creator-info">
                    <div>Created by</div>
                    <button type="button" className="creator-button">
            <span className="creator-details">
              <img
                  alt=""
                  loading="lazy"
                  width="16"
                  height="16"
                  className="creator-image"
                  src={funContent.avatar}
              />
              <span className="creator-name">{funContent.createdBy}</span>
            </span>
                    </button>
                </div>
                <div className="market-info">
                    market cap: {funContent.marketCap}
                    <div className="badge-info">
                        [badge: <img loading="lazy" width="18" height="18" src="/images/king.webp"/>]
                    </div>
                </div>
                <p className="replies-info">replies: {funContent.replies}</p>
                <p className="title">
                    <span style={{fontWeight: "bold"}}>{String(funContent.title).replaceAll(":", "")}</span>
                </p>
            </div>
        </div>
    );
}

export default Logo;