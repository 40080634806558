import React from 'react';
import "./footer.css";

const Footer: React.FC = () => (
    <div className="footer">
        <div className="inside-footer">
            <p>© pump.fun 2024</p>
            <p className="google">This site is protected by reCAPTCHA and the Google <a target="_blank"
                                                                                        href="https://www.google.com/intl/en/policies/privacy/">Privacy
                Policy</a> and <a target="_blank" href="https://www.google.com/intl/en/policies/terms/">Terms of
                Service</a> apply.
            </p>
        </div>
    </div>
);

export default Footer;