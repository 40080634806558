import React from 'react';
import "./search.css";
import {Button, Input, Space} from "antd";

const Search: React.FC = () => (
    <div className="search-container">
        <div className="inside-search">
            <Input size="large" placeholder="search for token" style={{background: "#6ee7b7"}}/>
            <Button size="large" type="primary" className="btn-search">search</Button>
        </div>
    </div>
);

export default Search;