import React, {useEffect, useState} from 'react';
import {Button} from "antd";
import "./header.css";

const Header: React.FC = () => {
    const [randomKey, setRandomKey] = useState<string>("1");

    const getRandomInRange = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        // If randomizing is enabled, start the interval
        intervalId = setInterval(() => {
            setRandomKey(Math.random().toString(36).substr(2, 9))
        }, getRandomInRange(500, 1800));

        // Clear the interval when randomizing is turned off or the component unmounts
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [randomKey]);

    return (
        <nav className="header">
            <div className="first-items">
                <img
                    alt="Pump"
                    loading="lazy"
                    src="/images/logo.webp"
                    width={25}
                    height={25}
                />
                <div className="btn-link">
                    <div className="btn-link-group">
                        <a href="https://twitter.com/pumpdotfun" target="_blank" rel="noopener noreferrer">[twitter]</a>
                        <a href="https://t.me/pumpfunsupport" target="_blank" rel="noopener noreferrer">[support]</a>
                    </div>
                    <div className="btn-link-group">
                        <a href="https://t.me/launchonpump" target="_blank" rel="noopener noreferrer">[telegram]</a>
                        <button type="button">[how it works]</button>
                    </div>
                </div>
            </div>
            <div className="second-items">
                <div key={`${randomKey}-1`} className="bumping">
                    <img alt="" loading="lazy" width="16" height="16" decoding="async"
                         data-nimg="1"
                         src="https://pump.mypinata.cloud/ipfs/QmVt2hgvL5vTQfUN58cFPiuXGYXycTuB9EF55rGGefqDFs?img-width=16&amp;img-dpr=2&amp;img-onerror=redirect"/>
                    <span>7tmpr bought 0.2000 SOL of R/MOON</span>
                    <img alt="" loading="lazy" width="20" height="20" decoding="async"
                         data-nimg="1"
                         src="https://pump.mypinata.cloud/ipfs/QmXmiHTe6QPBLUY19XA9Lvnc4QWSofhPVfwUsWBwwyGAe9?img-width=20&amp;img-dpr=2&amp;img-onerror=redirect"/>
                </div>
                <div key={`${randomKey}-2`} className="bumping" style={{backgroundColor: "#93c5fd"}}>
                    <img alt="" loading="lazy" width="16" height="16" decoding="async"
                         data-nimg="1"
                         src="https://pump.mypinata.cloud/ipfs/QmY392dVKJXHg8kFsfzTrEuGJAw4qGYByBmJabwxhi3sUM?img-width=16&amp;img-dpr=2&amp;img-onerror=redirect"/>
                    <span>bump2636 sold 0.0120 SOL of USDTea</span>
                    <img alt="" loading="lazy" width="20" height="20" decoding="async"
                         data-nimg="1"
                         src="https://pump.mypinata.cloud/ipfs/Qmf8ojQLRiYVqi346CxJ4w42SzWhu3EjFHLVh96CX197Qu?img-width=20&amp;img-dpr=2&amp;img-onerror=redirect"/>
                </div>
            </div>
            <div className="third-items">
                <div className="connect-wallet">
                    <button type="button">[connect wallet]</button>
                </div>
            </div>
        </nav>
    );
}

export default Header;